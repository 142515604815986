.footer-box .help-box {
  padding-top: 40px;
  padding-bottom: 80px;
  border-bottom: solid 1px #363636;
}
.footer-box .help-box .help-service {
  display: flex;
}
.footer-box .help-box .help-service .help-item {
  width: 160px;
}
.footer-box .help-box .help-service .help-item .help-item-span {
  display: block;
  font-size: 16px;
  color: #7a7a7a;
  margin-bottom: 20px;
}
.footer-box .help-box .help-service .help-item .help-item-a {
  display: block;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 14px;
}
.footer-box .help-box .ewm-box .ewm-item {
  text-align: center;
}
.footer-box .help-box .ewm-box .ewm-item .ewm-title {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 24px;
}
.footer-box .footer-copyrights {
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  padding: 35px 0;
}
.footer-box .footer-copyrights a {
  color: #ffffff;
}
.footer-box .footer-copyrights a:hover {
  color: #cccccc;
}
.footer-box .footer-copyrights .footer-gap {
  color: #7a7a7a;
  position: relative;
  top: -1px;
  margin: 0 5px;
}

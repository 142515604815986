.template-container {
    .tpl-item {
        border-radius: 8px;
        margin-bottom: 30px;


        .tpl-item-bg {
            position: relative;
            padding-top: 60%;
            overflow: hidden;
            border-radius: 8px;

            .item-box {
                width: 100%;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    border: none;
                    vertical-align: top;
                }
            }

            .tpl-mask {
                display: none;
                width: 100%;
                padding: 0 10%;
                position: absolute;
                bottom: 20px;
                left: 0;
                text-align: center;

                .preview {
                    width: 50%;
                    color: #ffffff;
                    font-size: 14px;
                    height: 40px;
                    line-height: 40px;
                    display: inline-block;
                    background-image: linear-gradient(90deg, #6253e1, #852d91, #f24645);
                    border-radius: 50px 0 0 50px;
                    transition: all 0.4s ease-in-out;
                }

                .usetpl {
                    width: 50%;
                    color: #ffffff;
                    font-size: 14px;
                    height: 40px;
                    line-height: 40px;
                    display: inline-block;
                    border-radius: 0 50px 50px 0;
                    background-image: linear-gradient(90deg,
                            #25aae1,
                            #40e495,
                            #30dd8a,
                            #2bb673);
                }
            }

            .tpl-used {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.5);
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                .used-box {
                    .used-icon {
                        font-size: 40px;
                        color: #8a8888;
                    }

                    .used-title {
                        font-size: 16px;
                        color: #8a8888;
                        margin: 10px;
                    }
                }

            }
        }


        .tpl-title {
            font-size: 16px;
            margin: 15px 0;

            .tpl-dispose {
                float: right;
            }
        }

        .tpl-code {
            font-size: 12px;
            color: #999;
        }

    }

    .tpl-item:hover>.tpl-item-bg>.tpl-mask {
        display: flex;
        display: block;
    }

    .tpl-mask a:hover {
        transition: all 0.4s ease-in-out;
        background-position: 100% 0;
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;
.body-box {
  min-height: 600px;
}
.body-box .line {
  position: relative;
}
.body-box .line .line-block .block-box {
  min-height: 100px;
}
.body-box .line .bar-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 2px dashed #46be8a;
  display: none;
}
.body-box .line .bar-layer .line-bar {
  position: absolute;
  left: 0;
  top: -42px;
  background-color: #3e3e3e;
  color: #f5f5f5;
  transition: .2s ease;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  display: flex;
  z-index: 3;
}
.body-box .line .bar-layer .line-bar .bar-title {
  padding: 0 18px;
  background-color: #494949;
  cursor: default;
}
.body-box .line .bar-layer .line-bar .bar-item {
  padding: 0 18px;
  cursor: pointer;
}
.body-box .line .bar-layer .line-bar .bar-item .bar-item-text {
  display: inline-block;
  height: 40px;
}
.body-box .line .bar-layer .line-bar .bar-item:hover {
  background: #222;
  color: #fff;
}
.body-box .line:hover > .bar-layer {
  display: flex;
}
.control-bar-box {
  position: fixed;
  z-index: 4;
  right: 20px;
  top: 100px;
  width: 240px;
  padding: 20px;
  overflow-y: scroll;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px #f2f2f2;
  pointer-events: auto;
  border: none;
}
.control-bar-box .item {
  margin-bottom: 20px;
}
.control-bar-box .item .item-button {
  text-align: left;
}
.control-bar-box .item .item-button .item-icon {
  cursor: move;
  display: block;
  padding: 4px;
  float: left;
}
.control-bar-box .item .item-button .item-text {
  width: 100px;
  overflow: hidden;
}
.control-bar-box .item .item-button .item-delete {
  display: none;
  padding: 4px;
  float: right;
  cursor: help;
}
.control-bar-box .item .button-active {
  border: 1px dashed #46be8a;
  color: #46be8a;
}
.control-bar-box .item:hover > .item-button > .item-delete {
  display: inline-block;
}
.control-bar-box .add-home-item {
  border: none;
  color: #ffffff;
  background-color: #46be8a;
}

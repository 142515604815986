.full-modal {
    .ant-modal-close {
        display: none;
    }

    .ant-modal-header {
        display: none;
    }
}

.editor-box {
    width: 100%;
}

.page-control {
    position: fixed;
    z-index: 5;
    left: 5px;
    top: 100px;

    .page-control-btn {
        background-color: #ffffff;
        border-color: #ffffff;
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;
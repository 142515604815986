.full-modal .ant-modal {
  max-width: 100%;
  top: 0;
  padding-bottom: 0;
  margin: 0;
}
.full-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
}
.full-modal .ant-modal-body {
  flex: 1;
  padding: 0;
}
.full-modal .ant-modal-body .template-body {
  display: flex;
  height: 100%;
}
.full-modal .ant-modal-body .template-body .navigation {
  width: 180px;
  height: 100%;
  border-right: 1px solid #f2f2f2;
  padding: 24px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.full-modal .ant-modal-body .template-body .data-body {
  flex-grow: 1;
}
.full-modal .ant-modal-body .template-body .data-body .page-home {
  display: flex;
  height: 100%;
}
.full-modal .ant-modal-body .template-body .data-body .page-home .home-left {
  width: 240px;
  text-align: center;
  height: 100%;
  border-right: 1px solid #f2f2f2;
}
.full-modal .ant-modal-body .template-body .data-body .page-home .home-left .home-title {
  padding: 20px;
  border-bottom: 1px solid #f2f2f2;
}
.full-modal .ant-modal-body .template-body .data-body .page-home .home-left .home-items {
  padding: 20px;
  height: calc(100% - 64px);
  overflow-y: scroll;
}
.full-modal .ant-modal-body .template-body .data-body .page-home .home-left .home-items .item {
  margin-bottom: 20px;
}
.full-modal .ant-modal-body .template-body .data-body .page-home .home-left .home-items .item .item-button {
  text-align: left;
}
.full-modal .ant-modal-body .template-body .data-body .page-home .home-left .home-items .item .item-button .item-icon {
  cursor: move;
  display: block;
  padding: 4px;
  float: left;
}
.full-modal .ant-modal-body .template-body .data-body .page-home .home-left .home-items .item .item-button .item-text {
  width: 100px;
  overflow: hidden;
}
.full-modal .ant-modal-body .template-body .data-body .page-home .home-left .home-items .item .item-button .item-delete {
  display: none;
  padding: 4px;
  float: right;
  cursor: help;
}
.full-modal .ant-modal-body .template-body .data-body .page-home .home-left .home-items .item .button-active {
  border: 1px dashed #46be8a;
  color: #46be8a;
}
.full-modal .ant-modal-body .template-body .data-body .page-home .home-left .home-items .item:hover > .item-button > .item-delete {
  display: inline-block;
}
.full-modal .ant-modal-body .template-body .data-body .page-home .home-left .home-items .add-home-item {
  border: none;
  color: #ffffff;
  background-color: #46be8a;
}
.full-modal .ant-modal-body .template-body .data-body .page-home .home-right {
  flex-grow: 1;
}
.full-modal .ant-modal-body .template-body .data-body .page-page {
  display: flex;
}
.full-modal .ant-modal-body .template-body .data-body .page-page .data-tab {
  width: 100px;
  height: 100%;
}
.full-modal .ant-modal-body .template-body .data-body .page-page .data-tab .data-tab-menu {
  height: 100%;
}
.full-modal .ant-modal-body .template-body .data-body .page-page .data-right {
  flex-grow: 1;
}

.full-modal {
    .ant-modal {
        max-width: 100%;
        top: 0;
        padding-bottom: 0;
        margin: 0;
    }

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: calc(100vh);
    }

    .ant-modal-body {
        flex: 1;
        padding: 0;

        .template-body {
            display: flex;
            height: 100%;

            .navigation {
                width: 180px;
                height: 100%;
                border-right: 1px solid #f2f2f2;
                padding: 24px;
                overflow-y: scroll;
                overflow-x: hidden;
            }

            .data-body {
                flex-grow: 1;

                .page-home {
                    display: flex;
                    height: 100%;

                    .home-left {
                        width: 240px;
                        text-align: center;
                        height: 100%;
                        border-right: 1px solid #f2f2f2;

                        .home-title {
                            padding: 20px;
                            border-bottom: 1px solid #f2f2f2;
                        }

                        .home-items {
                            padding: 20px;
                            height: calc(~"100% - 64px");
                            overflow-y: scroll;

                            .item {
                                margin-bottom: 20px;

                                .item-button {
                                    text-align: left;

                                    .item-icon {
                                        cursor: move;
                                        display: block;
                                        padding: 4px;
                                        float: left;
                                    }

                                    .item-text {
                                        width: 100px;
                                        overflow: hidden;
                                    }

                                    .item-delete {
                                        display: none;
                                        padding: 4px;
                                        float: right;
                                        cursor: help;
                                    }
                                }

                                .button-active {
                                    border: 1px dashed #46be8a;
                                    color: #46be8a;
                                }
                            }

                            .item:hover>.item-button>.item-delete {
                                display: inline-block;
                            }

                            .add-home-item {
                                border: none;
                                color: #ffffff;
                                background-color: #46be8a;
                            }
                        }
                    }

                    .home-right {
                        flex-grow: 1;
                    }
                }

                .page-page {
                    display: flex;

                    .data-tab {
                        width: 100px;
                        height: 100%;

                        .data-tab-menu {
                            height: 100%;
                        }
                    }

                    .data-right {
                        flex-grow: 1;
                    }
                }

            }
        }
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;
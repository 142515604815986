.tab-list {
  border: 1px solid #f0f0f0;
}
.tab-list .tab-item .list-item {
  width: 100%;
  margin: 6px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

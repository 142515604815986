.header-box {
  min-height: 50px;
}
.navigation {
  width: 100%;
}
.navigation .nav-box {
  height: 100%;
  display: flex;
}
.navigation .nav-box .nav-item {
  display: inline-block;
  line-height: 100%;
  position: relative;
}
.navigation .nav-box .nav-item a {
  line-height: 100%;
  color: #fff;
  display: inline-block;
  padding: 0 20px;
  width: 100%;
}
.navigation .nav-box .nav-item .nav-child {
  position: absolute;
  background-color: #2c83c7;
  z-index: 5;
  display: none;
}
.navigation .nav-box .nav-item .nav-child .nav-item {
  width: 100%;
}
.navigation .nav-box .active {
  background-color: #0969aa;
}
.navigation .nav-box .nav-item:hover {
  background-color: #1872af;
}
.navigation .nav-box .nav-item:hover .nav-child {
  display: block;
}

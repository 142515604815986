.article-list {
  min-height: 200px;
  border: 1px solid #f0f0f0;
}
.article-list .article-nav {
  font-size: 18px;
  padding: 10px 20px;
  border-bottom: 1px solid #f0f0f0;
}
.article-list .article-nav .more {
  font-size: 14px;
  display: block;
  float: right;
  color: #333333;
}
.article-list .list-items-box {
  padding: 10px;
}
.article-list .list-items-box .list-item {
  width: 100%;
  margin: 6px 0;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.tab-list {
  border: 1px solid #f0f0f0;
  .tab-item {
    .list-item {
      width: 100%;
      margin: 6px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@primary-color: #7f7fd5;@link-color: #86a8e7;
.footer-box {
    .help-box {
        padding-top: 40px;
        padding-bottom: 80px;
        border-bottom: solid 1px #363636;

        .help-service {
            display: flex;

            .help-item {
                width: 160px;

                .help-item-span {
                    display: block;
                    font-size: 16px;
                    color: #7a7a7a;
                    margin-bottom: 20px;
                }

                .help-item-a {
                    display: block;
                    font-size: 14px;
                    color: #ffffff;
                    margin-bottom: 14px;
                }
            }
        }

        .ewm-box {
            .ewm-item {
                text-align: center;

                .ewm-title {
                    font-size: 18px;
                    color: #ffffff;
                    margin-bottom: 24px;
                }
            }

        }
    }

    .footer-copyrights {
        text-align: center;
        color: #ffffff;
        font-size: 12px;
        padding: 35px 0;

        a {
            color: #ffffff;
        }

        a:hover {
            color: #cccccc;
        }

        .footer-gap {
            color: #7a7a7a;
            position: relative;
            top: -1px;
            margin: 0 5px;
        }
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;
.header-box {
    min-height: 50px;

    .header-left-box {}

    .header-right-box {}

}

.navigation {
    width: 100%;

    .nav-box {
        height: 100%;

        display: flex;

        .nav-item {
            display: inline-block;
            line-height: 100%;

            position: relative;

            a {
                line-height: 100%;
                color: #fff;
                display: inline-block;
                padding: 0 20px;
                width: 100%;
            }

            .nav-child {
                position: absolute;
                background-color: #2c83c7;
                z-index: 5;
                display: none;

                .nav-item {
                    width: 100%;
                }
            }
        }

        .active {
            background-color: #0969aa;
        }

        .nav-item:hover {
            background-color: #1872af;

            .nav-child {
                display: block;
            }
        }
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;
.rich-content {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
    font-size: 14px;
    color: #000000d9;
    overflow-wrap: break-word;
    text-indent: 24px;
    line-height: 40px;
    padding: 10px;
    border: 1px solid #f1f1f1;
}
@primary-color: #7f7fd5;@link-color: #86a8e7;
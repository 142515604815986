.block-box {
  position: relative;
  min-height: 100px;
  z-index: 3;
}
.block-box .block-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 2px dashed #000000;
  display: none;
}
.block-box .block-layer .block-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #3e3e3e;
  color: #f5f5f5;
  transition: .2s ease;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  display: flex;
}
.block-box .block-layer .block-bar .bar-title {
  padding: 0 18px;
  background-color: #494949;
  cursor: default;
}
.block-box .block-layer .block-bar .bar-item {
  padding: 0 18px;
  cursor: pointer;
}
.block-box .block-layer .block-bar .bar-item .bar-item-text {
  display: inline-block;
  height: 40px;
}
.block-box .block-layer .block-bar .bar-item:hover {
  background: #222;
  color: #fff;
}
.block-box:hover > .block-layer {
  display: flex;
}


.article-list {
  min-height: 200px;
  border: 1px solid #f0f0f0;

  .article-nav {
    font-size: 18px;
    padding: 10px 20px;
    border-bottom: 1px solid #f0f0f0;

    .more {
      font-size: 14px;
      display: block;
      float: right;
      color: #333333;
    }
  }

  .list-items-box {
    padding: 10px;

    .list-item {
      width: 100%;
      margin: 6px 0;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@primary-color: #7f7fd5;@link-color: #86a8e7;